import OpportunityTypes from '@helpers/opportunity-types';
import { Link } from '@ui/atoms';
import { theme } from "tailwind.config";

export const brandName = 'Jiraaf';
export const RiskDisclosureUrl = 'https://www.jiraaf.com/risk-disclosure';
export const AdvisoryForInvestors = 'https://jiraaf-public.s3.ap-south-1.amazonaws.com/Advisory_For_Investors.pdf'
export const VoluntaryFreezingPolicy =
    'https://jiraaf-public.s3.ap-south-1.amazonaws.com/Policy_on_Risk_Management_Voluntary_Freezing.pdf';

export const adminInvestorColumns = [
    'Country Code',
    'Contact Number',
    'Name',
    'Registration Date',
    'KYC Status',
    'Nse Status',
    'KYC submitted date',
    'Action',
];
export const kycInvestorColumns = [
    'Name',
    'Country Code',
    'Mobile Number',
    'Date of Upload',
    'Status',
    'Nse Status',
    'Action',
];

export const dematVerificationColumns = [
    'Name',
    'Email',
    'Contact Number',
    'PAN',
    'Demat',
    'Action',
]

export const multipleBankAccountManualVerificationColumns = [
    'Name',
    'Email',
    'Contact Number',
    'New Bank Account Added on',
    'Status',
    'Action',
]

export const adminUrlsToEnableSearch = ['/admin/investors'];
export const notInvestorPaths = ['/admin', '/adminLogin'];

export const nseWorkflowStatusFilterOptions = [
    {
        label: 'All',
        value: '',
    },
    {
        label: 'Pending with checker',
        value: 'PendingWithChecker',
    },
    {
        label: 'Returned by checker',
        value: 'ReturnedByChecker',
    },
    {
        label: 'Rejected by checker',
        value: 'RejectedByChecker',
    },
    {
        label: 'Pending With Exchange',
        value: 'PendingWithExchange',
    },
    {
        label: 'Approved',
        value: 'Approved',
    },
    {
        label: 'Rejected',
        value: 'Rejected',
    },
    {
        label: 'Returned',
        value: 'Returned',
    },
];

export const kycFilterOptions = [
    {
        label: 'All',
        value: '',
    },
    {
        label: 'Pending',
        value: 'Pending',
    },
    {
        label: 'Verified',
        value: 'Verified',
    },
    {
        label: 'Manual Verification Required',
        value: 'ManualVerificationRequired',
    },
];
export const contactUsHeaderHeading = 'We would love to hear from you';
export const contactUsHeaderDescription =
    'Write to us or call us with your feedback or your queries';

export const socialMediaIcons = [
    {
        src: '/images/contact-us/twitter.svg',
        link: ' https://www.twitter.com/Jiraaf_Official',
    },
    {
        src: '/images/contact-us/linkedIn.svg',
        link: 'https://www.linkedin.com/company/jiraafofficial',
    },
    {
        src: '/images/contact-us/instagram.svg',
        link: 'https://www.instagram.com/jiraaf_official/',
    },
    {
        src: '/images/contact-us/facebook.svg',
        link: 'https://www.facebook.com/Jiraaf_Official',
    },
];

export const supportPhoneNumber = '+91 8953265326';
export const supportEmail = 'help@jiraaf.com';

export const contactCards = [
    {
        icon: '/images/contact-us/call-us.svg',
        label: 'Call Us',
        value: supportPhoneNumber,
        type: 'phone',
    },
    {
        icon: '/images/contact-us/email-us.svg',
        label: 'Email Us',
        value: supportEmail,
        type: 'email',
    },
    {
        icon: '/images/contact-us/Send.svg',
        label: 'Connect',
        labelLongName: 'Connect With Us',
        value: null,
        icons: socialMediaIcons,
        type: 'link',
    },
];

export const contactInfo = [
    {
        label: 'Stock Broker',
        value: 'Jiraaf Platform Private Limited ',
    },
    {
        label: 'Registration Number',
        value: 'INZ000315538',
    },
];

export const panAlreadyExistMessage = `The PAN you have entered is already associated with another account on Jiraaf. You cannot have the same PAN associated with more than one account with us.`;

export const opportunityBreadcrumbs = (value) => [
    { title: 'Opportunities', url: '/opportunities' },
    { title: value, active: true },
];
export const investorDetailsBreadcrumbs = (value) => [
    {
        title: 'Home',
        url: '/',
    },
    {
        title: value,
        active: true,
    },
];

export const kycStatusModified = {
    Verified: {
        src: '/images/rightTick.svg',
        status: 'Verified',
    },
    ManualVerificationRequired: {
        src: '/images/processClock.svg',
        status: 'In Verification',
    },
    Rejected: {
        src: '/images/exclamatoryError.svg',
        status: 'Rejected',
    },
    Pending: {
        src: '/images/pending.svg',
        status: 'Pending',
    },
};

export const getOpportunityType = (opportunityType) => {
    switch (opportunityType) {
        case OpportunityTypes.CORPORATE_BOND:
            return 'Corporate Bond';
        case OpportunityTypes.SDL:
            return 'State Development Loan';
        case OpportunityTypes.TREASURY_BILL:
            return 'Treasury Bill';
        case OpportunityTypes.SOVEREIGN_GOLD_BONDS:
            return 'Sovereign Gold Bond';
        case OpportunityTypes.SECURITISED_DEBT_INSTRUMENT:
            return 'Securitised Debt Instrument';
        case OpportunityTypes.G_SEC:
            return 'Government Security';
        case 'AssetBackedLeasing':
            return 'Asset Backed Leasing';
        case 'InvoiceDiscounting':
            return 'Invoice Discounting';
        default:
            return 'Invoice Discounting';
    }
};

export const investmentReviewBreadcrumbs = (opportunity) => [
    { title: 'Opportunities', url: '/opportunities' },
    {
        title: `${getConterPartyName(opportunity)}`,
        url: `/opportunity/${opportunity.id}`,
    },
    { title: 'Investment', active: true },
];

const quickOptions = [1, 2, 3];

export const quickAmountSelectionOptions = (multiple) => {
    return quickOptions.map((option) => option * multiple);
};
export const otpExpiryTime = 60;

export const staticOpportunity = (id) =>
    ['2d688a3c-3f76-4003-843a-2b7152c5eb01'].includes(id);

export const addressProofTypes = [
    { name: 'Aadhar Card', id: 'Aadhar' },
    { name: 'Voter Id', id: 'VoterId' },
    { name: 'Passport', id: 'Passport' },
];

export const kycImagePrefix = process.env.NEXT_PUBLIC_ADDRESS_PROOF_PREFIX;

export const INVESTOR_KYC_BUCKET = process.env.NEXT_PUBLIC_INVESTOR_KYC_BUCKET;

export const KRA_DOWNLOAD_BUCKET = process.env.NEXT_PUBLIC_KRA_DOWNLOADS_BUCKET;

export const OPPORTUNITY_INVESTORS_BUCKET =
    process.env.NEXT_PUBLIC_OPPORTUNITY_INVESTORS_BUCKET;

export const INVESTOR_OPPORTUNITY_DOCUMENTS_BUCKET =
    process.env.NEXT_PUBLIC_INVESTOR_OPPORTUNITY_DOCUMENTS_BUCKET;

export const getHeaderAndDescriptionForDoubleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description: 'Your Details should be clearly readable',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description: 'Your Details should be clearly readable',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description: 'Your Details should be clearly readable',
            };
        default:
            break;
    }
};

export const getHeaderAndDescriptionForSingleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        default:
            break;
    }
};

export const getDoubleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                front: {
                    header: 'Upload front side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-front.png',
                },
                back: {
                    header: 'Upload back side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-back.png',
                },
            };
        case 'VoterId':
            return {
                front: {
                    header: 'Upload front side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-front.png',
                },
                back: {
                    header: 'Upload back side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-back.png',
                },
            };
        case 'Passport':
            return {
                front: {
                    header: 'Upload Photo page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-front.png',
                },
                back: {
                    header: 'Upload Address page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-back.png',
                },
            };
        default:
            break;
    }
};

export const getSingleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                defaultImageUrl: '/images/investors/kyc/aadhar-full.png',
            };
        case 'VoterId':
            return {
                defaultImageUrl: '/images/investors/kyc/voterId-full.png',
            };
        case 'Passport':
            return {
                defaultImageUrl: '/images/investors/kyc/passport-full.png',
            };
        default:
            break;
    }
};

export const getSingleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: 'If you have a two separate images for Aadhar and wish to upload each side separately, click',
        VoterId:
            'If you have a two separate images for Voter ID and wish to upload each side separately, click',
        Passport:
            'If you have a two separate images for Passport and wish to upload each side separately, click',
    };
    return addressTypes[addressType];
};

export const getDoubleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: "Don't have a two-sided for Aadhaar?\nTo upload a single page Aadhaar click",
        VoterId:
            "Don't have a two separated images for Voter ID?\nTo upload a single file of Voter ID click",
        Passport:
            "Don't have a two separated images for Passport?\nTo upload a single file of Passport click",
    };
    return addressTypes[addressType];
};

export const allowedFileUploads =
    'image/png, image/jpeg, image/jpg, application/pdf, image/tiff';

export const defaultUploadedPdfIcon =
    '/images/investors/kyc/default-pdf-icon.svg';

export const opportunityStates = {
    InDraft: {
        displayName: 'In Draft',
        color: 'bg-yellow-300',
        textColor: 'text-semantic-info-base'
    },
    SubcriptionReady: {
        displayName: 'Open for Subscription',
        color: 'bg-yellow-600',
        textColor: 'text-semantic-info-base'
    },
    InvestmentReady: {
        displayName: 'Open for Investment',
        color: 'bg-green-400',
        textColor: 'text-semantic-success-base'
    },
    OngoingRepayment: {
        displayName: 'Ongoing Repayment',
        color: 'bg-semantic-success-base',
        textColor: 'text-semantic-info-base'
    },
    PrepaidAndClosed: {
        displayName: 'Prepaid And Closed',
        color: 'bg-blue-700',
        textColor: 'text-semantic-info-base'
    },
    MaturedAndClosed: {
        displayName: 'Matured And Closed',
        color: 'bg-basicBlack',
        textColor: 'text-semantic-info-base'
    },
};

export const navbarMenu = [
    { title: 'Opportunities', url: '/opportunities' },
    { title: 'Dashboard', url: '/dashboard' },
];

export const isNri = (countryOfOrigin = '') => {
    return countryOfOrigin.toLowerCase() !== 'india';
};

export const isCountryOfOriginIndia = (countryCode) => countryCode === '+91';

export const getConterPartyName = (opportunity) => {
    return opportunity?.borrower?.name;
};

export const getConterPartyLogo = (opportunity) => {
    return opportunity?.primaryCounterParty?.logo?.url ? opportunity?.primaryCounterParty?.logo?.url : opportunity?.borrower?.logo?.url;
};

export const isUpcomingOpportunity = (opportunity) => {
    return opportunity?.state === 'InDraft';
};

export const isTreasuryBillOpportunity = (opportunityType) => {
    return opportunityType === 'TreasuryBill';
};

export const isComboDeal = (opportunity) =>
    opportunity?.bundledType === 'Parent';

export const isComboDealChild = (investment) =>
    investment.bundledType === 'Child';

export const isCommercialDisplayAssetClass = (displayAssetClass) =>
    displayAssetClass && displayAssetClass.toLowerCase() === 'commercial paper';

export const getConterPartyAbout = (opportunity) => {
    return opportunity?.borrower?.about;
};

export const getPayoutFrequencyTitle = (opportunity) => {
    switch (opportunity?.type) {
        default:
            return 'Pricipal Payout Frequency';
    }
};

export const opportunityClosedStates = [
    'OngoingRepayment',
    'PrepaidAndClosed',
    'MaturedAndClosed',
];

export const opportunityFullyRepaidStates = [
    'PrepaidAndClosed',
    'MaturedAndClosed',
];

const teamBaseUrl = {
    linkedIn: 'https://www.linkedin.com/in',
    imageUrl: 'https://team-members-jiraaf.s3.ap-south-1.amazonaws.com',
};

export const TeamMembers = [
    {
        title: 'Founders',
        members: [
            {
                name: 'Saurav Ghosh',
                designation: 'Founder',
                description:
                    'Saurav has 10 + years’ experience in corporate finance with leading Real Estate developers. Alumnus of BITS, Pilani, Goa Campus & IIM-A.',
                linkedIn: `${teamBaseUrl.linkedIn}/saurav-ghosh-5345905/`,
                photo: `${teamBaseUrl.imageUrl}/Saurav+Ghosh.webp`,
            },
            {
                name: 'Vineet Agrawal',
                designation: 'Founder',
                description:
                    'Vineet has 12+ years’ experience with Real Estate and Hospitality businesses, managing end to end life cycle of investments. Alumnus of RVCE & XIM-B.',
                linkedIn: `${teamBaseUrl.linkedIn}/vineet-agrawal-8aa5359/`,
                photo: `${teamBaseUrl.imageUrl}/Vineet+Agarwal.webp`,
            },
        ],
    },
    {
        title: '',
        members: [
            {
                name: 'Vikas Bansal',
                designation: 'Vice President- Investment',
                description:
                    'Vikas comes with 14+ years across Corporate Banking, his last stint was with Kotak Mahindra Bank, alumnus of IIM Lucknow & IIT Kharagpur.',
                linkedIn: `${teamBaseUrl.linkedIn}/bonsikool/`,
                photo: `${teamBaseUrl.imageUrl}/Vikas+Bansal.webp`,
            },
            {
                name: 'Prateek Goyal',
                designation: 'Chief Startegy officer',
                description:
                    'Prateek comes with 10+ years across investment banking, private equity & venture capital, alumnus of IIM-A.',
                linkedIn: `${teamBaseUrl.linkedIn}/prateek-goyal-43a46429/`,
                photo: `${teamBaseUrl.imageUrl}/Prateek+Goyal.webp`,
            },
            {
                name: 'Jayaprakash K',
                designation: 'Chief Growth Officer',
                description:
                    'An MBA grad from Gies College of Business, JP has 18+ years of experience in the banking and finserv industries. He has earlier worked at HSBC, Home Credit and Cohen & Steers.',
                linkedIn: `${teamBaseUrl.linkedIn}/jp-kandasami/`,
                photo: `${teamBaseUrl.imageUrl}/Jayaprakash+K.webp`,
            },
            {
                name: 'Vasu Gulati',
                designation: 'Head - Engineering',
                description:
                    'An alumnus of NIT Rourkela, Vasu has 17+ years of experience in designing & developing SaaS, B2C, and B2B systems. He has previously worked at ShipBob Inc, Barclays and Cognizant.',
                linkedIn: `${teamBaseUrl.linkedIn}/vasugulati/`,
                photo: `${teamBaseUrl.imageUrl}/Vasu+Gulati.webp`,
            },
            {
                name: 'Apoorv Gawde',
                designation: 'Chief Product Officer',
                description:
                    'Apoorv has 17+ years of experience in leading product teams to build and deliver customer value across domains. He has been instrumental in delivering multiple fintech platforms over the last 6 years.',
                linkedIn: `${teamBaseUrl.linkedIn}/apoorvgawde/`,
                photo: `${teamBaseUrl.imageUrl}/Apoorv+Gawde.webp`,
            },
            {
                name: 'Ankit Bhartia',
                designation: 'Director - Finance and Treasury',
                description:
                    'Ankit is a Real Estate Expert with 14 yrs of experience across Credit and Relationship Management in Wholesale Real Estate Funding division of HSBC, ICICI Bank, Yes Bank and Piramal Capital, alumnus of IIM Kozhikode. CFA and FRM certified.',
                linkedIn: `${teamBaseUrl.linkedIn}/ankitbhartia/`,
                photo: `${teamBaseUrl.imageUrl}/Ankit+Bhartia.webp`,
            },
            // {
            //     name: 'Surbhi Bagri',
            //     designation: 'Investments',
            //     description:
            //         'An alumnus of NMIMS (Mumbai), Surbhi has previously worked with Unitus Capital Inclusive Credit and has an overall experience of 4+ years.',
            //     linkedIn: `${teamBaseUrl.linkedIn}/surbhibagri1903/`,
            //     photo: `${teamBaseUrl.imageUrl}/Surbhi+Bargi.webp`,
            // },
            // {
            //     name: 'Shraddha Tiwari',
            //     designation: 'Investments',
            //     description:
            //         'An MBA grad from IMS (Indore), Shraddha has 6 years of exp. In relationship management roles at Kotak Mahindra Bank and IndusInd Bank.',
            //     linkedIn: `${teamBaseUrl.linkedIn}/shraddha-tiwari-78b1827a/`,
            //     photo: `${teamBaseUrl.imageUrl}/Shraddha+Tiwari.webp`,
            // },
            // {
            //     name: 'Ananya Singh',
            //     designation: 'Investments',
            //     description:
            //         'Ananya has previously worked in the corporate lending space with Northern Arc Capital, underwriting deals and managing a portfolio of Rs 80+ Cr for emerging corporates.',
            //     linkedIn: `${teamBaseUrl.linkedIn}/ananyasingh9623/`,
            //     photo: `${teamBaseUrl.imageUrl}/Ananya+Singh.webp`,
            // },
            // {
            //     name: 'Kaivalya Sopariwala',
            //     designation: 'Investments',
            //     description:
            //         'An alumnus of FMS, Delhi, Kaivalya has 4+ years of experience as an investment manager at IDFC First Bank.',
            //     linkedIn: `${teamBaseUrl.linkedIn}/kaivalya-sopariwala-83783789/`,
            //     photo: `${teamBaseUrl.imageUrl}/Kaivalya+Sopariwala.webp`,
            // },
            // {
            //     name: 'Yashvardhan Lohani',
            //     designation: 'Investments',
            //     description:
            //         'Yashvardhan has 4+ years of work experience in Investment Banking, previously worked with a Luxembourg based Hedge Fund.',
            //     linkedIn: `${teamBaseUrl.linkedIn}/yashvardhan-lohani-55274212a/`,
            //     photo: `${teamBaseUrl.imageUrl}/Yashvardhan+Lohani.webp`,
            // },
            // {
            //     name: 'Mudita Vats',
            //     designation: 'Investments',
            //     description:
            //         'Mudita comes with an experience of 5+ years in financial services and consulting domains having previously worked at KPMG.',
            //     linkedIn: `${teamBaseUrl.linkedIn}/mudita-vats-7a4a15123/`,
            //     photo: `${teamBaseUrl.imageUrl}/Mudita+Vats.webp`,
            // },
        ],
    },
    // {
    //     title: 'Growth',
    //     members: [
    //         {
    //             name: 'Jayaprakash K',
    //             designation: 'Head - Growth',
    //             description:
    //                 'An MBA grad from Gies College of Business, JP has 18+ years of experience in the banking and finserv industries. He has earlier worked at HSBC, Home Credit and Cohen & Steers.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/jp-kandasami/`,
    //             photo: `${teamBaseUrl.imageUrl}/Jayaprakash+K.webp`,
    //         },
    //         {
    //             name: 'Aditya Jha',
    //             designation: 'Training and Quality',
    //             description:
    //                 'Aditya has earlier worked with Teleperformance & Nearby Tech and has 6+ years of experience in customer training.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/aditya-j-228734250/`,
    //             photo: `${teamBaseUrl.imageUrl}/Aditya+Jha.webp`,
    //         },
    //         {
    //             name: 'Aswin Kumar V',
    //             designation: 'Marketing',
    //             description:
    //                 'Aswin comes with 8 years of Digital Marketing experience. He is an alumnus of BITS Pilani and has worked with Lenovo, Saint-Gobain, and Nippon Paint.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/aswinkumarv/`,
    //             photo: `${teamBaseUrl.imageUrl}/Aswin+Kumar+V.webp`,
    //         },
    //         {
    //             name: 'Sheenu Gaur',
    //             designation: 'Brand',
    //             description:
    //                 'Sheenu comes with overall 8 years of experience in Advertising.  Throughout her tenure, she has worked with a diverse set of clientele. Experience of working on the nationwide launch and renovations marketing campaigns.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/sheenu-gaur-08a54549/`,
    //             photo: `${teamBaseUrl.imageUrl}/Sheenu+Gaur.webp`,
    //         },
    //         {
    //             name: 'Pramit Shah',
    //             designation: 'Channel Sales',
    //             description:
    //                 'Pramit has 15 years of experience in the financial services industry. He has previously worked with Faircent.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/pramit-shah-5338b2205/`,
    //             photo: `${teamBaseUrl.imageUrl}/Pramit+Shah.webp`,
    //         },
    //         {
    //             name: 'Rahul Widhani',
    //             designation: 'Channel Sales',
    //             description:
    //                 'Rahul comes with 13 years of Sales & Marketing experience. He is an alumnus of MANIT, Bhopal and has worked with BFSI Like Motilal Oswal, HDFC BANK, AXIS BANK and YES BANK.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/rahul-widhani-72145055/`,
    //             photo: `${teamBaseUrl.imageUrl}/Rahul+Widhani.webp`,
    //         },
    //         {
    //             name: 'Raju Singh',
    //             designation: 'Channel Sales Manager',
    //             description:
    //                 'Raju Singh comes with 15 years of Business Development & Products experience. He is an MBA and has worked with SBI Caps, iFAST Financial, and Finzy in his previous assignments.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/raju-singh-`,
    //             photo: `${teamBaseUrl.imageUrl}/Raju+Singh.webp`,
    //         },
    //         {
    //             name: 'Ramya G',
    //             designation: 'Investor Relations',
    //             description:
    //                 'Ramya comes with 7 years of Customer Care and Operations experience. She has an MBA in Finance and has worked with Standard Charted Bank & OLA in her previous assignments.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/ramya-g-9a634a94/`,
    //             photo: `${teamBaseUrl.imageUrl}/Ramya+G.webp`,
    //         },

    //         {
    //             name: 'Santanu Biswas',
    //             designation: 'Channel Sales',
    //             description:
    //                 '16 years of business development experience in B2B sales. Demonstrated track record of successfully establishing business of financial product distributors.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/santanu-biswas/`,
    //             photo: `${teamBaseUrl.imageUrl}/Santanu+Biswas.webp`,
    //         },
    //         {
    //             name: 'Sonal Parekh',
    //             designation: 'Relationship Management',
    //             description:
    //                 'Sonal has 6+ years in Retail Banking and Fintech as a Service delivery & Growth expert',
    //             linkedIn: `${teamBaseUrl.linkedIn}/sonal-parekh-2270b7112/`,
    //             photo: `${teamBaseUrl.imageUrl}/Sonal+Parekh.webp`,
    //         },
    //         {
    //             name: 'Sheetal Jain',
    //             designation: 'Relationship Management',
    //             description:
    //                 'Sheetal comes with 9+ years of experience in the financial services sector. She has previously worked with ICICI Securities and Kotak Securities.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/sheetal-jain-59732075/`,
    //             photo: `${teamBaseUrl.imageUrl}/Sheetal+Jain.webp`,
    //         },
    //         {
    //             name: 'Komal Shukla',
    //             designation: 'Relationship Management',
    //             description:
    //                 'Komal comes with 4+ years of corporate sales experience with Reliance Capital, ICICI and Standard Chartered Bank.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/komal-shukla-94798b128/`,
    //             photo: `${teamBaseUrl.imageUrl}/Komal+Shukla.webp`,
    //         },
    //         {
    //             name: 'Srinivasan Iyengar',
    //             designation: 'Channel Sales',
    //             description:
    //                 'Srini is a Management Graduate having an overall experience of 14 years in BFSI Segment. Worked with companies like Principal AMC, BOI AXA Investment Managers, Aditya Birla Financial services and Finzy.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/komal-shukla-94798b128/`,
    //             photo: `${teamBaseUrl.imageUrl}/Srinivasan+Iyengar.webp`,
    //         },
    //         {
    //             name: 'Soumya S',
    //             designation: 'Investments',
    //             description:
    //                 'Soumya comes with 2 years of work ex. from Tata Consultancy Services, and Cognizant.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/soumya-srinivas-2225801a1/`,
    //             photo: `${teamBaseUrl.imageUrl}/Soumya+S.webp`,
    //         },
    //     ],
    // },
    // {
    //     title: 'Product & Engineering',
    //     members: [
    //         {
    //             name: 'Vasu Gulati',
    //             designation: 'Head - Engineering',
    //             description:
    //                 'An alumnus of NIT Rourkela, Vasu has 17+ years of experience in designing & developing SaaS, B2C, and B2B systems. He has previously worked at ShipBob Inc, Barclays and Cognizant.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/vasugulati/`,
    //             photo: `${teamBaseUrl.imageUrl}/Vasu+Gulati.webp`,
    //         },
    //         {
    //             name: 'Apoorv Gawde',
    //             designation: 'Head - Product',
    //             description:
    //                 'Apoorv has 17+ years of experience in leading product teams to build and deliver customer value across domains. He has been instrumental in delivering multiple fintech platforms over the last 6 years.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/apoorvgawde/`,
    //             photo: `${teamBaseUrl.imageUrl}/Apoorv+Gawde.webp`,
    //         },
    //         {
    //             name: 'Swati Vittal',
    //             designation: 'Product',
    //             description:
    //                 'Has a total experience of 10 years in technology companies across several sectors (Pharma, Legal, Fintech). The last 6 years she has been a Product Owner at different tech startups building their platforms.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/swati-vittal/`,
    //             photo: `${teamBaseUrl.imageUrl}/Swati+Vittal.webp`,
    //         },
    //         {
    //             name: 'Dilip C',
    //             designation: 'Engineering',
    //             description:
    //                 'Dilip Chandra has 5+ years of experience in developing applications for banks, fintechs and legal firms. He is a full stack developer, who can manage end to end tech requirements of the company.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/dilip-chandra-471955133/`,
    //             photo: `${teamBaseUrl.imageUrl}/Dilip+C.webp`,
    //         },
    //         {
    //             name: 'Anil Kumar Samal',
    //             designation: 'Engineering',
    //             description:
    //                 'Anil has 6 + years of hands-on experience in building large-scale applications in the cloud and is also a passionate learner.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/anilkumarsamal/`,
    //             photo: `${teamBaseUrl.imageUrl}/Anil+Kumar+Samal.webp`,
    //         },
    //         {
    //             name: 'Piyush Prabhakar',
    //             designation: 'Engineering',
    //             description:
    //                 'Piyush Prabhakar has 5+ years of Software Testing (UI & API) experience. He has worked in Fintech and E-Commerce domain.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/piyush-prabhakar-4030a520/`,
    //             photo: `${teamBaseUrl.imageUrl}/Piyush+Prabhakar.webp`,
    //         },
    //         {
    //             name: 'Rajat Thakur',
    //             designation: 'Engineering',
    //             description:
    //                 'An engineering grad from Panjab Uni., Rajat has 3+ years of experience in QA roles. His previous stint was at SDP Labs.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/rajat-thakur-26923112b/`,
    //             photo: `${teamBaseUrl.imageUrl}/Rajat+Thakur.webp`,
    //         },
    //         {
    //             name: 'Vishwanth Reddy',
    //             designation: 'Engineering',
    //             description:
    //                 'A mech. engineering grad from Amrita University, Vishwanth comes with an experience of 2+ years in full-stack development.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/y-vishwanth-reddy/`,
    //             photo: `${teamBaseUrl.imageUrl}/Vishwanth+Reddy.webp`,
    //         },
    //         {
    //             name: 'Aniruddha Singha',
    //             designation: 'Engineering',
    //             description:
    //                 'A Btech grad, Aniruddha has 2+ years of experience as a software developer. He has earlier worked with DigiUnity.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/aniruddha-singha-86bba4140/`,
    //             photo: `${teamBaseUrl.imageUrl}/Aniruddha+Singha.webp`,
    //         },
    //         {
    //             name: 'Paras Sharma',
    //             designation: 'Engineering',
    //             description:
    //                 'A Btech grad from SRM University grad, Paras has earlier worked at Infosys for 1.5+ years in a software development role.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/pb07/`,
    //             photo: `${teamBaseUrl.imageUrl}/Paras+Sharma.webp`,
    //         },
    //         {
    //             name: 'Abhijeet Agarwal',
    //             designation: 'Engineering',
    //             description:
    //                 'A mechanical engg grad, Abhijeet has 3+ years of experience in talent acquisition roles. He has previously worked at ShipBob Inc and Collar Search.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/goyalabhijeet/`,
    //             photo: `${teamBaseUrl.imageUrl}/Abhijeet+Agarwal.webp`,
    //         },
    //     ],
    // },
    // {
    //     title: 'Risk & Finance',
    //     members: [
    //         {
    //             name: 'Ankit Bhartia',
    //             designation: 'Head - Risk and Finance',
    //             description:
    //                 'Ankit is a Real Estate Expert with 14 yrs of experience across Credit and Relationship Management in Wholesale Real Estate Funding division of HSBC, ICICI Bank, Yes Bank and Piramal Capital, alumnus of IIM Kozhikode. CFA and FRM certified.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/ankitbhartia/`,
    //             photo: `${teamBaseUrl.imageUrl}/Ankit+Bhartia.webp`,
    //         },
    //         {
    //             name: 'Ratnakar Patra',
    //             designation: 'Risk Management',
    //             description:
    //                 'An NIT-R and IIM-L grad, Ratnakar has 4+ years of experience in credit & risk roles at Axis and HDFC Bank.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/ratnakar-patra-829a606a/`,
    //             photo: `${teamBaseUrl.imageUrl}/Ratnakar+Patra.webp`,
    //         },
    //         {
    //             name: 'Ayush Khandelwal',
    //             designation: 'Finance and Accounts',
    //             description:
    //                 'A Chartered Accountant and CMA, Ayush has 6+ years of experience across SFBs and fintech firms.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/ayush-khandelwal-b545bba5/`,
    //             photo: `${teamBaseUrl.imageUrl}/Ayush+Khandelwal.webp`,
    //         },
    //         {
    //             name: 'Chandrababu R',
    //             designation: 'Finance and Accounts',
    //             description:
    //                 'An MBA grad from Sikkim Manipal Uni., Chandrababu has 17+ years of experience in finance & accounts roles. His earlier stints were at Shoba Ltd and Ozone Group.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/chandrababukpm/`,
    //             photo: `${teamBaseUrl.imageUrl}/Chandrababu+R.webp`,
    //         },
    //         {
    //             name: 'Prithvi N',
    //             designation: 'Finance and Accounts',
    //             description:
    //                 'Prithvi has 9+ years of work experience in Accounting and Finance.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/prithvi-n-9aa825225/`,
    //             photo: `${teamBaseUrl.imageUrl}/Prithvi+N.webp`,
    //         },
    //     ],
    // },
    // {
    //     title: 'Human Resource and Operations',
    //     members: [
    //         {
    //             name: 'Sathya M',
    //             designation: 'Human Resources',
    //             description:
    //                 'Sathya is a versatile person and comes with a master’s degree in human resources and an overall experience of 8 Years in all end to end HR practices.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/sathya-mayalagu-ab807834/`,
    //             photo: `${teamBaseUrl.imageUrl}/Sathya+M.webp`,
    //         },
    //         {
    //             name: 'Shreyas Ningaiah',
    //             designation: 'Administration',
    //             description:
    //                 'Shreyas has 5+ years of work experience as an office administrator.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/shreyas171993/`,
    //             photo: `${teamBaseUrl.imageUrl}/Shreyas+Ningaiah.webp`,
    //         },
    //     ],
    // },
    // {
    //     title: 'Legal',
    //     members: [
    //         {
    //             name: 'Prakash Chandra Mishra',
    //             designation: 'Legal',
    //             description:
    //                 'Prakash is an experienced transaction lawyer in Corporate & Commercial Law Practice over 5 years. Ex-Fox Mandal and alumnus of NLSIU, Bengaluru.',
    //             linkedIn: `${teamBaseUrl.linkedIn}/prakash-chandra-mishra-a470887b/`,
    //             photo: `${teamBaseUrl.imageUrl}/Prakash+Mishra.webp`,
    //         },
    //     ],
    // },
];

export const maxFileSize = 2000;

export const confidenceScore = 0.65;

export const userColumns = [
    'Country Code',
    'Mobile Number',
    'Signup Date',
    'Email',
];

export const bankVerificationSuccessfulStatusCode = 101;
export const maxRetries = 3;

export const karzaServerStatusCodes = {
    400: {
        description: 'Bad Request',
    },
    503: {
        description: 'Service Unavailable',
    },
    504: {
        description: 'Request Timed Out',
    },
    402: {
        description: 'Insufficient Credits',
    },
};

export const karzaInternalStatusCodes = {
    101: {
        action: 'Approval',
        description: 'Valid Authentication',
    },
    102: {
        customMessage: 'Please enter valid Bank Details',
        action: 'Alert',
        description: 'Invalid ID number or combination of inputs',
    },
    104: {
        action: 'Manual',
        description: 'Max Retries Exceeded',
    },
    107: {
        action: 'Manual',
        description: 'Not Supported',
    },
    108: {
        action: 'Manual',
        description: 'Internal Resource Unavailable',
    },
};

export const bankVerificationKYCStatusCodes = {
    KC01: {
        customMessage: 'Transaction Successful',
        action: 'Approval',
        description: 'Transaction Successful',
        adminMessage: 'Approval',
        pennyDropMessage: 'Approval',
    },
    KC02: {
        action: 'Manual',
        description: 'Credit Transaction Failed',
    },
    KC03: {
        customMessage:
            'Please check your Bank Account Number or IFSC code is incorrect',
        action: 'Alert',
        description: 'Invalid Beneficiary Account Number or IFSC',
        adminMessage:
            "Please check either investor's bank account number or IFSC code is incorrect",
        pennyDropMessage:
            'Please check whether the bank account number or IFSC code is incorrect',
    },
    KC04: {
        action: 'Manual',
        description: 'Amount Limit Exceeded',
    },
    KC05: {
        customMessage: 'Please make sure your bank account is Active',
        action: 'Alert',
        description: 'Account Blocked/Frozen',
        adminMessage: "Please check Investor's Account might not be Active",
        pennyDropMessage:
            'The bank account might not be active. Please verify.',
    },
    KC06: {
        customMessage:
            'This is a NRE account. Please provide another bank account',
        action: 'Alert',
        description: 'NRE Account',
        adminMessage:
            'This is a NRE account. Please ask Investor to provide another account',
        pennyDropMessage:
            'This is a NRE account. Please provide another bank account',
    },
    KC07: {
        customMessage:
            'We are not able to verify this account. Please ensure that your bank account is Active. Alternately provide a different account',
        action: 'Alert',
        description: 'Account Closed',
        adminMessage: "Please check Investor's Account might be Closed",
        pennyDropMessage: 'The bank account might be closed. Please verify.',
    },
    KC08: {
        action: 'Manual',
        description: 'Limit Exceeded For Member Bank',
    },
    KC09: {
        action: 'Manual',
        description: 'Transaction Not Allowed',
    },
    KC10: { action: 'Manual', description: 'Function Not Valid' },
    KC11: {
        action: 'Manual',
        description: 'Aadhaar Belong To Remitter Bank',
    },
    KC12: {
        action: 'Manual',
        description: 'Transaction Not Allowed',
    },
    KC13: {
        action: 'Manual',
        description: 'Customer Transaction Limit Exceeded',
    },
    KC14: {
        action: 'Approval',
        description: 'Payee Is An Individual And Not A Merchant',
        adminMessage: 'Approval',
        pennyDropMessage: 'Approval',
    },
    KC15: {
        action: 'Approval',
        description: 'Payee Is A Merchant And Not An Individual',
        adminMessage: 'Approval',
        pennyDropMessage: 'Approval',
    },
    KC16: {
        customMessage:
            'This is a NRE account. Please provide another bank account',
        action: 'Alert',
        description: 'Foreign Inward Remittance Not Allowed',
        adminMessage:
            'This is a NRE account. Please ask Investor to provide another account',
        pennyDropMessage:
            'This is a NRE account. Please provide another bank account.',
    },
    KC17: {
        action: 'Manual',
        description: 'Transaction Not Allowed As Invalid Payment Reference',
    },
    KC18: { action: 'Manual', description: 'Invalid Amount' },
    KC19: {
        action: 'Manual',
        description: 'Invalid Remitter Account Number',
    },
    KC20: { action: 'Manual', description: 'General Error' },
    KC21: {
        customMessage:
            'Please check your Bank Account Number or IFSC code is incorrect',
        action: 'Manual',
        description: 'Invalid Transaction Type',
        pennyDropMessage:
            'Please check whether your bank account number or IFSC code is incorrect.',
    },
    KC22: {
        action: 'Manual',
        description: 'Invalid Amount Field',
    },
    KC23: {
        action: 'Manual',
        description: 'IMPS Service not available for the selected bank',
    },
    KC24: {
        action: 'Manual',
        description: 'Duplicate Transaction',
    },
    KC25: {
        action: 'Manual',
        description: 'Beneficiary Bank Not Enable For P2a',
    },
    KC26: {
        action: 'Manual',
        description: 'Insufficient Balance In Pool A/C',
    },
    KC27: {
        customMessage:
            'We are not able to verify this account. Please ensure that your account details are valid',
        action: 'Alert',
        description: 'Invalid Account',
        pennyDropMessage:
            'We are not able to verify this account. Please ensure that the account details are valid.',
    },
    KC30: {
        action: 'Manual',
        description: 'Invalid Response Code',
    },
    KC31: {
        action: 'Manual',
        description: 'Exceeds Account Limit',
    },
    KC32: { action: 'Manual', description: 'Unable To Process' },
    KC33: { action: 'Manual', description: 'Invalid Message' },
    KC34: { action: 'Manual', description: 'Suspected Fraud' },
    KC35: {
        action: 'Manual',
        description: 'Transfer Amount Exceeds Limit',
    },
    KC36: { action: 'Manual', description: 'Key Sync Error' },
    KC37: { action: 'Manual', description: 'Timed Out' },
    KC38: { action: 'Manual', description: 'CBS Offline' },
    KC39: { action: 'Manual', description: 'Unmapped Error' },
    KC40: {
        customMessage:
            'We are not able to verify this account. Please ensure that you have provided the right IFSC code',
        action: 'Alert',
        description: 'Invalid Beneficiary IFSC Code or NBIN',
        adminMessage: 'Please check the IFSC code',
        pennyDropMessage: 'Please check the IFSC code.',
    },
    KC41: {
        action: 'Manual',
        description: 'Transaction Not Allowed',
    },
    KC42: {
        action: 'Manual',
        description: 'Foreign Inward Remittance For P2P Only',
    },
    KC43: { action: 'Manual', description: 'Invalid Channel' },
    KC44: {
        action: 'Manual',
        description: 'Authentication Failure',
    },
    KC45: {
        action: 'Manual',
        description: 'Invalid Account Type',
    },
    KC46: {
        action: 'Manual',
        description: 'Invalid Merchant Account',
    },
    KC47: {
        action: 'Manual',
        description: 'Invalid Remitter Name',
    },
    KC48: {
        action: 'Manual',
        description:
            'Successful Transaction With Generic Name Returned In Account Name',
    },
    KC52: {
        action: 'Manual',
        description: 'Duplicate Reference Number',
    },
    KC56: {
        action: 'Manual',
        description: 'Response Code Not Maintained',
    },
};

export const netbankingLimitAmount = 300000;

export const addressProofs = [
    'Aadhar',
    'DrivingLicense',
    'VoterId',
    'Passport',
];

export const jiraafSafePreferenceBreadcrumbs = (value) => [
    {
        title: 'JiraafSafe',
        url: '/dashboard/jiraaf-safe',
    },
    {
        title: value,
        active: true,
    },
];

const investorsImageBaseUrl = '/images/homepage/investors';

export const companyInvestors = [
    {
        name: 'Siddharth Shah',
        position: 'Co-founder, Pharmeasy',
        image: `${investorsImageBaseUrl}/Siddharth Shah.png`,
    },
    {
        name: 'Dharmil Sheth',
        position: 'Co-founder, Pharmeasy',
        image: `${investorsImageBaseUrl}/Dharmil Sheth.png`,
    },
    {
        name: 'Ramakant Sharma',
        position: 'Co-founder, LivSpace',
        image: `${investorsImageBaseUrl}/Ramakant Sharma.png`,
    },
    {
        name: 'Mankekar',
        position: 'Family Office',
        image: `${investorsImageBaseUrl}/Mankekar.svg`,
    },
];

const valuesImageBaseUrl = '/images/values';
export const CompanyValues = [
    {
        value: 'Customer obsession',
        description:
            'Deliver customer experience every day by bringing exclusive products and exceptional services.',
        image: `${valuesImageBaseUrl}/customerObsession.svg`,
    },
    {
        value: 'Collaborate',
        description:
            'We believe in establishing a culture of collaboration and inclusion, encouraging teamwork, openness, and entrepreneurship.',
        image: `${valuesImageBaseUrl}/collaborate.svg`,
    },
    {
        value: 'Excellence',
        description:
            'Our unwavering commitment to excellence is what enables us to consistently exceed the expectations of our customers, partners and that of our team.',
        image: `${valuesImageBaseUrl}/excellence.svg`,
    },
    {
        value: 'Integrity',
        description:
            'We are committed to doing the right thing and uphold highest ethical standards with authenticity and transparency.',
        image: `${valuesImageBaseUrl}/integrity.svg`,
    },
    {
        value: 'Make a difference every day',
        description:
            'Our team strives to learn, adapt and improve every day to make a difference in our organisation and in the communities we serve.',
        image: `${valuesImageBaseUrl}/puzzle.svg`,
    },
    {
        value: 'Ownership',
        description:
            'We take ownership and accountability for all tasks and actions undertaken and completing them on time.',
        image: `${valuesImageBaseUrl}/ownership.svg`,
    },
];

export const CompanyMilestones = [
    {
        month: "Dec '21",
        transactionVolume: 60, // in crores
        registeredInvestors: 1200,
        teamSize: 19,
        dealsLaunched: 20,
    },
    {
        month: "Mar '22",
        transactionVolume: 200,
        registeredInvestors: 6700,
        teamSize: 35,
        dealsLaunched: 35,
    },
    {
        month: "Jun '22",
        transactionVolume: 350,
        registeredInvestors: 16500,
        teamSize: 48,
        dealsLaunched: 85,
    },
    {
        month: "Sep '22",
        transactionVolume: 560,
        registeredInvestors: 29000,
        teamSize: 54,
        dealsLaunched: 150,
    },
    {
        month: "Dec '22",
        transactionVolume: 870,
        registeredInvestors: 51000,
        teamSize: 71,
        dealsLaunched: 320,
    },
    {
        month: "Mar '23",
        transactionVolume: 1300,
        registeredInvestors: 75000,
        teamSize: 71,
        dealsLaunched: 320,
    },
];

export const phoneInputStyle = {
    containerStyle: {
        borderRadius: '8px',
        borderWidth: '0px',
        borderColor: 'rgba(209, 213, 219, var(--tw-border-opacity))',
    },
    inputStyle: {
        width: '100%',
        height: '100%',
        borderWidth: '0px',
        fontSize: '16px',
        paddingLeft: '4.5rem',
        backgroundColor: theme?.colors?.gray?.[100],
    },
    buttonStyle: {
        borderWidth: '0px',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        width: '4rem',
    },
    dropdownStyle: {
        overflow: 'auto',
        height: '92px',
    },
};

export const getMobileNumberLength = (code) => {
    switch (code) {
        case '971':
            return 9;
        case '65':
            return 8;
        case '61':
            return 9;
        case '974':
            return 8;
        case '966':
            return 9;
        case '49':
            return [11, 12];
        case '33':
            return 9;
        case '60':
            return 9;
        default:
            return 10;
    }
};

export const getAssetClassBorderColor = (type) => {
    switch (type) {
        case OpportunityTypes.CORPORATE_BOND:
            return 'rgb(255, 200, 202)';
        case 'VentureDebt':
            return 'rgb(194, 227, 255)';
        // TODO CHANGHE DEFAULT COLOR
        default:
            return 'rgb(163, 225, 222)';
    }
};

export const productStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    name: 'Products | Jiraaf',
    url: 'https://www.jiraaf.com/products',
    itemListElement: [
        {
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': 'https://www.jiraaf.com/products/corporate-bond',
                name: 'Corporate Bond',
            },
        },
        {
            '@type': 'ListItem',
            position: 2,
            item: {
                '@id': 'https://www.jiraaf.com/products/sovereign-gold-bonds',
                name: 'Sovereign Gold Bonds',
            },
        },
        {
            '@type': 'ListItem',
            position: 3,
            item: {
                '@id': 'https://www.jiraaf.com/products/treasury-bills',
                name: 'Treasury Bills',
            },
        },
        {
            '@type': 'ListItem',
            position: 4,
            item: {
                '@id': 'https://www.jiraaf.com/products/g-secs-sdls',
                name: 'G-Sec and SDL',
            },
        },
    ],
};

export const aboutUsStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    name: 'About Us | Jiraaf',
    url: `${process.env.NEXT_PUBLIC_APP_URL}/about-us`,
    itemListElement: [
        {
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us`,
                name: 'Our Company',
            },
        },
        // {
        //     '@type': 'ListItem',
        //     position: 2,
        //     item: {
        //         '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us#milestones`,
        //         name: 'Our Milestones',
        //     },
        // },
        {
            '@type': 'ListItem',
            position: 3,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us#values`,
                name: 'Our Values',
            },
        },
        {
            '@type': 'ListItem',
            position: 4,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us#investors`,
                name: 'Investors',
            },
        },
        {
            '@type': 'ListItem',
            position: 5,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/team`,
                name: 'Team',
            },
        },
    ],
};

export const careerStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Careers | Jiraaf',
    url: 'https://www.jiraaf.com/careers',
};

export const contactUsStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Contact Us | Jiraaf',
    url: 'https://www.jiraaf.com/contact-us',
    foundingDate: '2021',
    founders: [
        {
            '@type': 'Person',
            name: 'Saurav Ghosh',
        },
        {
            '@type': 'Person',
            name: 'Vineet Agrawal',
        },
    ],
    address: {
        '@type': 'PostalAddress',
        streetAddress:
            'Clayworks, 371, 3rd Floor, 1st Cross Road, Santhoshapuram',
        addressLocality: 'Koramangala 3rd Block',
        addressRegion: 'Bengaluru',
        postalCode: '560034',
        addressCountry: 'INDIA',
    },
    contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '+91-8953265326',
        email: 'help@jiraaf.com',
    },
    sameAs: [
        'https://www.twitter.com/Jiraaf_Official/',
        'https://www.linkedin.com/company/jiraafofficial/',
        'https://www.instagram.com/jiraaf_official/',
        'https://www.facebook.com/Jiraaf_Official',
    ],
};

// country codes for phoneInput
export const userCountryCodesInput = [
    'in',
    'ca',
    'us',
    'gb',
    'ae',
    'sg',
    'au',
    'fr',
    'de',
    'my',
    'qa',
    'sa',
];

// mobile number length check
export const isValidMobileNumber = (value, country) => {
    if (value.length > country?.countryCode?.length) {
        const mobileLength = getMobileNumberLength(country?.countryCode);
        if (typeof mobileLength === 'object') {
            // range length
            const digits = value.length - country.countryCode.length;
            if (
                mobileLength?.length >= 2 &&
                digits >= Number(mobileLength[0]) &&
                digits <= Number(mobileLength[1])
            ) {
                return true;
            }
        } else if (value.length - country.countryCode.length === mobileLength) {
            return true;
        }
    }
    return false;
};


export const disclaimerStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Disclaimer | Jiraaf',
    url: 'https://www.jiraaf.com/disclaimer',
};

export const investorCharterStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Investor Charter | Jiraaf',
    url: 'https://www.jiraaf.com/investor-charter',
};

export const nriDisclaimerStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'NRI Disclaimer | Jiraaf',
    url: 'https://www.jiraaf.com/nri-disclaimer',
};

export const grievanceStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Grievance Redressal | Jiraaf',
    url: 'https://www.jiraaf.com/grievance-redressal',
};

export const faqStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'QAPage',
    name: 'Frequently Asked Questions | Online Bond Platform | Jiraaf',
    url: 'https://www.jiraaf.com/faqs',
};

export const homePageStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Jiraaf',
    url: 'https://www.jiraaf.com',
};

export const mediaStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    name: 'In the News | Jiraa',
    url: 'https://www.jiraaf.com/media',
};

export const privacyPolicyStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Privacy Policy | Jiraaf',
    url: 'https://www.jiraaf.com/privacy-policy',
};

export const riskDisclosureStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Risk Disclosure | Jiraaf',
    url: RiskDisclosureUrl,
};

export const teamStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Team | Jiraaf',
    url: 'https://www.jiraaf.com/team',
};

export const termAndConditionStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Terms and Conditions | Jiraaf',
    url: 'https://www.jiraaf.com/terms-and-condition',
};

export const investmentTermAndConditionStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Terms and Conditions | Jiraaf',
    url: 'https://www.jiraaf.com/investment-disclaimer',
};

export const webibarStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
    name: 'Webinars | Jiraaf',
    url: 'https://www.jiraaf.com/webinars',
};

export const homepageVideoUrl =
    'https://team-members-jiraaf.s3.ap-south-1.amazonaws.com/Jiraafwithoutmilestone02.02.2023.mp4';

export const RecentlyClosedOpportunityFilters = [
    {
        label: 'Counter party',
        name: 'counterParties',
    },
    {
        label: 'Asset Class',
        name: 'type',
    },
    {
        label: 'Sector',
        name: 'industries',
    },
    {
        label: 'Status',
        name: 'state',
    },
];

export const assetClassOptions = {
    CorporateDebt: 'Corporate Debt (unlisted)',
    TreasuryBill: 'Treasury Bills',
    VentureDebt: 'Venture Debt',
};

export const resultClosedDealsLimit = 6;

export const assetClassDetails = {
    CorporateDebt: {
        tenure: '12 - 36 months',
        irr: '10% - 16%',
    },
    VentureDebt: {
        tenure: '12 - 24 months',
        irr: '15% - 18%',
    },
    TreasuryBill: {
        tenure: '91 - 364 days',
        irr: '6% - 7%',
    },
};

export const closedFilterKeyValuesMap = {
    ...assetClassOptions,
    OngoingRepayment: 'Ongoing - Ongoing Repayment',
    MaturedAndClosed: 'Matured - Matured & Closed',
    PrepaidAndClosed: 'Matured - Prepaid & Closed',
};

export const ifaMasterCommisionPercentage = 1;

export const borrowerFollowRecords = [
    {
        first: 'Restrictions on repayment of other loans or corporate guarantees, until repaid to Jiraaf',
        second: 'Maintaining certain parameters such as Revenue, Costs, EBITDA, cash burn, etc.',
        align: 'around',
        image: '',
    },
    {
        first: 'Adherence to sufficient funds in Reserve Account for Servicing Debt',
        second: 'Restrictions on change in capital structure/management, shareholding pattern, etc.',
        image: '/images/CreditEvaluationProcess/jiraaf-orange-logo.svg',
        align: 'between',
    },
    {
        first: 'Preserving credit rating of the company and maintaining pre-determined debt-equity ratio',
        second: 'No fundamental change to company such as Name, Registered Office, Authorised Capital',
        image: '',
        align: 'around',
    },
];

export const CreditAssessmentLayers = [
    {
        step: '01',
        text: 'Pre-Sanction Credit Risk Assessment',
        link: '/credit-evaluation-process/#pre-sanction-risk-assessment',
    },
    {
        step: '02',
        text: (
            <>
                Deal <br className="hidden md:block" /> Structuring
            </>
        ),
        link: '/credit-evaluation-process/#deal-structuring',
    },
    {
        step: '03',
        text: 'Post-Sanction Monitoring',
        link: '/credit-evaluation-process/#post-sanction-monitoring',
    },
];

export const preSanctionRiskAssessmentData = [
    {
        title: 'Assessing Credibility',
        descHead: '',
        desc: [
            'Assessment of management credibility',
            'Adherence to legal, regulatory and statutory guidelines',
            'Review of past conduct with lenders',
            'Third-party verification',
            'Corporate governance concerns',
        ],
        image: '/images/CreditEvaluationProcess/AccessCredibility.svg',
        className: 'h-[72px] w-[72px] m-auto',
    },
    {
        title: 'Business Model Validation',
        descHead: 'Key factors analysed:',
        desc: [
            'Product and market evaluation',
            'Competitive analysis',
            'Financial metrics and margins',
            'Business risks',
        ],
        image: '/images/CreditEvaluationProcess/BusinessModelVerification.svg',
        className: 'h-[72px] w-[72px] m-auto',
    },
    {
        title: 'Ability to Repay on Time',
        descHead: '',
        desc: [
            'Sensitivity analysis and stress test of operating cash flows',
            'Ability to infuse and raise money',
            'Verification of cash reserves for working capital needs, debt obligations and equity fund flows',
        ],
        image: '/images/CreditEvaluationProcess/AbilityToRepay.svg',
        className: 'h-[72px] w-[72px] m-auto',
    },
];
// custom styles react-select
export const customStylesSelect = {
    // For the select it self, not the options of the select
    control: (styles, { isDisabled }) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            // This is an example: backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.5)' : 'white'
            color: isDisabled ? 'white' : theme?.colors?.gray?.[700],
            backgroundColor: isDisabled ? theme?.colors?.gray?.[200] : theme?.colors?.basicWhite,
            minHeight: '48px',
            cursor: 'pointer',
            fontSize: '16px',
            borderRadius: '6px',
            fontWeight: 400,
            textTransform: 'capitalize',
        };
    },
    indicatorSeparator: (state) => ({
        display: 'none',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: theme?.colors?.gray?.[300],
        textTransform: 'capitalize',
        border: 0,
        fontWeight: 500,
        fontSize: '14px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            textTransform: 'capitalize',
            fontSize: '14px',
        };
    },
    placeholder(base, state) {
        return {
            ...base,
            display: state.isFocused && 'none',
        };
    },
};

export const customStylesSelectUI = {
    ...customStylesSelect,
    control: (styles, { isDisabled }) => {
        return {
            ...customStylesSelect.control(styles, { isDisabled }),
            borderRadius: '8px',
            fontFamily: 'Montserrat, sans-serif',
            boxShadow: '0 !important',
            borderWidth: '1px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderColor: theme?.colors?.gray?.[300],
            '&:hover': {
                borderColor: theme?.colors?.gray?.[300],
            },
        };
    },
    singleValue: (provided) => ({
        ...customStylesSelect.singleValue(provided),
        color: theme?.colors?.gray?.[900],
        fontSize: '16px',
        fontWeight: 400,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? theme?.colors?.gray?.[50] : 'white',
            color: theme?.colors?.gray?.[900],
            textTransform: 'capitalize',
            fontSize: '14px',
        };
    },
    placeholder(base, state) {
        return {
            ...base,
            borderColor: theme?.colors?.gray?.[300],

        };
    },
};

export const opportunityPreferenceData = [
    {
        name: 'All investors',
        type: 'AllInvestor',
    },
    {
        name: 'Exclusively first time investors',
        type: 'OnlyForFirstTimeInvestor',
    },
];

export const nomineeRelationOptions = [
    { label: 'Father', value: 'Father' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Son', value: 'Son' },
    { label: 'Daughter', value: 'Daughter' },
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Brother', value: 'Brother' },
    { label: 'Sister', value: 'Sister' },
];

export const escalationTableData = [
    {
        detail: 'Customer care',
        contactPerson: 'Sarath Kumar Mohan',
        address:
            'No 371, St. Johns Hospital Road, Santoshpuram,  Koramangala, 3rd Block, Bangalore, South KA 560034',
        contactNumber: '+91 96113 12898',
        emailId: 'sarathkumar.m@jiraaf.com',
        workingHours: '9 AM - 6 PM All trading days',
    },
    {
        detail: 'Head of Customer Care',
        contactPerson: 'Azhar Abdul Pathan',
        address:
            'No 371, St. Johns Hospital Road, Santoshpuram,  Koramangala, 3rd Block, Bangalore, South KA 560034',
        contactNumber: '+91 96113 12705',
        emailId: 'azhar.p@jiraaf.com',
        workingHours: '9 AM - 6 PM All trading days',
    },
    {
        detail: 'Compliance Officer',
        contactPerson: 'Richa Mishra',
        address:
            'No 371, St. Johns Hospital Road, Santoshpuram, Koramangala, 3rd Block, Bangalore, South KA 560034',
        contactNumber: '+91 96113 12885',
        emailId: 'richa.m@jiraaf.com',
        workingHours: '9 AM - 6 PM All trading days',
    },
    {
        detail: 'CEO',
        contactPerson: 'Sheetal Jain',
        address:
            'No 371, St. Johns Hospital Road, Santoshpuram, Koramangala, 3rd Block, Bangalore, South KA 560034',
        contactNumber: '+91 96113 12383',
        emailId: 'sheetal.j@jiraaf.com',
        workingHours: '9 AM - 6 PM All trading days',
    },
];

export const escalationData = {
    bulletPoints: {
        heading:
            'In absence of a response/complaint not addressed to your satisfaction, you may lodge a complaint with:',
        subHeading: [
            <>
                SEBI at{' '}
                <Link
                    href={'https://scores.gov.in/scores/Welcome.html'}
                    children={'https://scores.gov.in/scores/Welcome.html'}
                    target="_blank"
                    className="break-words text-primary-500 p5-regular md:p4-regular"
                />
            </>,
            <>
                Exchange at{' '}
                <Link
                    href={'https://investorhelpline.nseindia.com/NICEPLUS/'}
                    children={'https://investorhelpline.nseindia.com/NICEPLUS'}
                    target="_blank"
                    className="break-words text-primary-500 p5-regular md:p4-regular"
                />
            </>,
        ],
    },
    Notes: [
        <>
            After exhausting options for resolution of the grievance stated
            above i.e. at our end and escalation made to SEBI SCORES to your
            satisfaction, you can initiate dispute resolution through the
            SMART ODR Portal @{' '}
            <Link
                href={'https://smartodr.in/login'}
                children={'https://smartodr.in/login'}
                target="_blank"
                className="break-words text-primary-500 p5-regular md:p4-regular"
            />
            .
        </>,
        <>
            Alternatively, you can also initiate dispute resolution through the
            SMART ODR Portal @{' '}
            <Link
                href={'https://smartodr.in/login'}
                children={'https://smartodr.in/login'}
                target="_blank"
                className="break-words text-primary-500 p5-regular md:p4-regular"
            />
            , if the grievance/complaint lodged with us was not satisfactorily
            resolved or at any stage of the subsequent escalations made to SEBI
            SCORES (prior to or at the end of such escalation/s).
        </>,
        <>
            Please quote your Service Ticket/Complaint Ref No. while raising
            your complaint at SEBI SCORES/Exchange portal /ODR portal.
        </>,
        <>
            Please click on the link to access our{" "}
            <Link
                href={`https://${process.env.NEXT_PUBLIC_MEDIA_ARTICLE_BUCKET}.s3.ap-south-1.amazonaws.com/Investor_Complaints_Data.pdf`}
                children={'investor grievances report'}
                target="_blank"
                className="break-words text-primary-500"
            />.
        </>,
    ],
};

export const kmpTableData = [
    {
        id: 1,
        contactPerson: 'Sheetal Jain',
        designation: 'Chief  Executive Officer',
        contactNumber: '+91 9611312383',
        emailId: 'sheetal.j@jiraaf.com',
    },
    {
        id: 2,
        contactPerson: 'Azhar Abdul Pathan',
        designation: 'Whole-time  Director',
        contactNumber: '+91 9611312705',
        emailId: 'azhar.p@jiraaf.com',
    },
    {
        id: 3,
        contactPerson: 'Saurav Ghosh',
        designation: 'Director',
        contactNumber: '+91 9611312320',
        emailId: 'saurav@jiraaf.com',
    },
    {
        id: 4,
        contactPerson: 'Richa Mishra',
        designation: 'Compliance  Officer',
        contactNumber: '+91 9611312885',
        emailId: 'richa.m@jiraaf.com',
    },
];

export const GaInvestmentTypes = {
    AMO: 'AMO',
    REGULAR: 'Regular'
}

export const getHomepageBannerImages = (config) => {
    switch (config) {
        case 'BannerVersion 2.0':
            return [
                '/images/amo-homepage-banner-desktop.webp',
                '/images/amo-homepage-banner-mobile.webp',
            ];
        default:
            return [];
    }
};

export const getCounterPartyLogo = (opportunity) => {
    return opportunity?.primaryCounterParty?.logo?.url
        ? opportunity?.primaryCounterParty?.logo?.url
        : opportunity?.borrower?.logo?.url;
};

export const investmentTimeFrameOption = [
    { label: 'In the next few weeks', value: 'FewWeeks' },
    { label: 'In the next few months', value: 'FewMonths' },
    { label: 'Not planning to invest', value: 'NotPlanning' },
];

// get country of origin from country code
export const getCountryOfOrigin = (countryCode) => {
    let countryOfOrigin;
    switch (countryCode) {
        case '+971':
            countryOfOrigin = 'UAE';
            break;
        case '+1':
            countryOfOrigin = 'US/Canada';
            break;
        case '+44':
            countryOfOrigin = 'UK';
            break;
        case '+65':
            countryOfOrigin = 'Singapore';
            break;
        case '+61':
            countryOfOrigin = 'Australia';
            break;
        case '+33':
            countryOfOrigin = 'France';
            break;
        case '+49':
            countryOfOrigin = 'Germany';
            break;
        case '+60':
            countryOfOrigin = 'Malaysia';
            break;
        case '+974':
            countryOfOrigin = 'Qatar';
            break;
        case '+966':
            countryOfOrigin = 'Saudi Arabia';
            break;
        default:
            countryOfOrigin = 'India';
            break;
    }
    return countryOfOrigin;
};


export const DEFAULT_PAGE_LIMIT = 10;

export const SOURAV_GHOSH = 'Saurav Ghosh';
export const VINEET_AGARWAL = 'Vineet Agrawal';